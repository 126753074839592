<template>
  <v-container fluid class="pa-0">
    <router-view />
    <vue-headful :title="pageTitle" />
  </v-container>
</template>

<script>
import vueHeadful from 'vue-headful';
import ConciergeHomeMixins from './mixins/ConciergeHomeMixins.vue';
import * as Sentry from '@sentry/vue';
export default {
  name: 'ConciergeLandingPage',
  components: {
    vueHeadful,
  },
  created() {
    Sentry.setTag('module', 'concierge');
  },
  mixins: [ConciergeHomeMixins],
  computed: {
    partnerName() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : '';
    },
    pageTitle() {
      return this.partnerName ?? 'Guestlogix';
    },
    hasConciergeErrors() {
      return (
        this.hasConciergeStagesError || this.hasConciergeModuleContentError
      );
    },
  },
  watch: {
    selectedStageId: {
      immediate: true,
      async handler(selectedStageId) {
        if (selectedStageId && this.$route.name !== 'concierge-stage') {
          await this.$router.push({
            name: 'concierge-stage',
            params: {
              stageId: selectedStageId,
            },
          });
        }
      },
    },
    async ['$route.params.stageId'](stageId = null) {
      await this.updateStage({ id: stageId });
    },
    hasConciergeErrors(val) {
      if (!!val) {
        const notFoundError =
          this.conciergeCategoriesError &&
          this.conciergeCategoriesError.status >= 400;

        const internalServerError =
          this.conciergeCategoriesError &&
          this.conciergeCategoriesError.status >= 500;

        if (internalServerError) this.$router.replace('/500');
        else if (notFoundError) this.$router.replace('/404');
      }
    },
  },
};
</script>
